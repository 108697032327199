<template>
	<v-form
		v-model="isValid"
		ref="formRef"
		lazy-validation
		@submit.prevent="onSubmit"
	>
		<v-card
			class="tw-overflow-x-hidden tw-overflow-y-auto tw-px-4 lg:tw-px-14 lg:tw-py-9 tw-py-6"
		>
			<h2 class="tw-font-Poppins-text tw-font-semibold tw-text-22 tw-mb-9">
				{{ isUpdate ? $t('actions.update') : 'Create new' }} Ad Reservation
			</h2>
			<v-card-text>
				<v-row>
					<v-col :cols="$currentUser.isAdmin ? 6 : 12">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('classification') }}
						</div>
						<v-select
							v-model="adv.adSpace"
							:items="adSpaces"
							:placeholder="$t('classification')"
							:rules="[rules.required($t('classification'))]"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							flat
							dense
							outlined
							return-object
							class="user-input"
							hide-details="auto"
							item-value="id"
							item-text="category.name"
						/>
					</v-col>

					<v-col v-if="$currentUser.isAdmin" cols="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('supplierLabel') }}
						</div>
						<v-autocomplete
							v-model="adv.supplier"
							:items="suppliers"
							:loading="!$suppliers.isLoaded"
							:rules="[rules.required($t('supplierLabel'))]"
							:placeholder="$t('selectSupplier')"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							flat
							dense
							outlined
							return-object
							class="user-input"
							hide-details="auto"
							item-value="id"
							item-text="representativeName"
							@update:search-input="onSearchSupplier"
						>
							<template #selection="{ item }">
								<template>{{ item.representativeName }}</template>
								<template v-if="item.company">
									({{ item.company.name }})
								</template>
							</template>
							<template #item="{ item }">
								<!-- v-intersect="
										(_, __, v) => onIntersect(v, item, index)
									" -->
								<v-list-item-title>
									<template>{{ item.representativeName }}</template>
									<template v-if="item.company">
										({{ item.company.name }})
									</template>
								</v-list-item-title>
							</template>
						</v-autocomplete>
					</v-col>

					<v-col cols="12" sm="6">
						<BaseDatePicker
							v-model="adv.fromStartDate"
							:min="$m().format(YYYY - MM - DD)"
							:max="adv.toEndDate"
							:label="$t('startDate')"
							:rules="[rules.required($t('startDate'))]"
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							:placeholder="$t('select-start-date')"
							input-class="user-input"
						/>
					</v-col>

					<v-col cols="12" sm="6">
						<BaseDatePicker
							v-model="adv.toEndDate"
							:min="adv.fromStartDate"
							:label="$t('endDate')"
							:rules="[rules.required($t('endDate'))]"
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							:placeholder="$t('select-end-date')"
							input-class="user-input"
						/>
					</v-col>

					<!-- READONLY -->
					<v-col cols="4">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('price-per-day') }}
						</div>
						<v-text-field
							:value="$get(adv, 'adSpace.chargePerDay', '')"
							:placeholder="$t('00')"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							flat
							dense
							readonly
							outlined
							prefix="SAR"
							class="user-input"
							hide-details="auto"
						/>
					</v-col>
					<v-col cols="4">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('headers.duration') }}
						</div>
						<v-text-field
							:value="totalDays"
							:placeholder="$t('number-of-days')"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							flat
							dense
							outlined
							readonly
							suffix="DAYS"
							class="user-input"
							hide-details="auto"
						/>
					</v-col>
					<v-col cols="4">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('total-price') }}
						</div>
						<v-text-field
							:value="totalPrice"
							:placeholder="$t('00')"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							flat
							dense
							readonly
							outlined
							prefix="SAR"
							class="user-input"
							hide-details="auto"
						/>
					</v-col>
					<!-- READONLY END -->

					<v-col cols="12">
						<BaseDropZone v-model="adv.attachment" />
					</v-col>
					<div
						class="tw-flex tw-space-s-6 tw-text-black tw-font-bold tw-items-center tw-px-4"
					>
						<h2>{{ $t('endorsement') }}</h2>
						<div>
							<v-switch
								v-model="adv.endorsement"
								hide-details="false"
								class="tw-mx-0 tw-my-0"
							/>
						</div>
					</div>
				</v-row>
			</v-card-text>

			<v-card-actions class="tw-mt-7 tw-flex tw-p-0">
				<v-btn
					text
					outlined
					height="58"
					color="primary"
					class="tw-flex-1 tw-rounded-lg"
					@click="onClose"
				>
					<span class="tw-text-lg tw-font-medium tw-capitalize tw-text-72">
						{{ $t('actions.cancel') }}
					</span>
				</v-btn>
				<v-btn
					:loading="isLoading"
					depressed
					height="58"
					type="submit"
					color="primary"
					class="tw-flex-1 tw-font-medium tw-rounded-lg"
				>
					<span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
					>
						{{ $t('actions.submit') }}
					</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-form>
</template>

<script>
import { toFormData } from 'vuelpers'
import { mapActions, mapGetters } from 'vuex'
import { createFormMixin } from '@/mixins/form-mixin'
import { getFile, toAttachment } from '../../helpers'

import UseFilePicker from '../../mixins/UseFilePicker'

export default {
	name: 'FormAdReservation',
	mixins: [
		UseFilePicker,
		createFormMixin({
			rules: ['required'],
		}),
	],
	props: {
		error: Object,
		onView: {
			type: Boolean,
			default: false,
		},
		ad: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			adv: {},
			isValid: false,
			isLoading: false,
		}
	},
	watch: {
		ad: {
			deep: true,
			immediate: true,
			handler(v) {
				this.adv = this.newAdReservation(v)
				this.$currentUser.isAdmin && this.getSupplier()
			},
		},
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('adSpaces', ['$adSpaces']),
		...mapGetters('suppliers', ['$suppliers']),
		isUpdate() {
			return !this.onView && !!this.adv.id
		},
		adSpaces() {
			return this.$adSpaces?.data ?? []
		},
		totalPrice() {
			if (!this.adv.adSpace || !this.totalDays) return ''
			return this.adv.adSpace.chargePerDay * this.totalDays
		},
		totalDays() {
			if (!this.adv.fromStartDate || !this.adv.toEndDate) return ''
			return this.$m(this.adv.toEndDate).diff(this.adv.fromStartDate, 'days')
		},
		hasInSuppliers() {
			if (!this.adv.supplier?.id) return true
			return this.$suppliers.data.some(supplier => {
				return supplier.id === this.adv.supplier?.id
			})
		},
		suppliers() {
			return !this.hasInSuppliers
				? [this.adv.supplier, ...this.$suppliers.data]
				: this.$suppliers.data
		},
	},
	methods: {
		...mapActions('suppliers', ['getSupplier']),
		...mapActions('advertisements', [
			'createAdvertisement',
			'updateAdvertisement',
		]),
		onSearchSupplier(e) {
			console.log('onSearchSupplier', e)
		},
		onClose() {
			this.$refs.formRef.reset()
			this.adv = this.newAdReservation()
			this.$emit('onClose')
		},
		newAdReservation(v = {}) {
			return {
				id: v?.id,
				advertisementId: v?.id,
				status: v?.status,
				adSpace: v?.adSpace,
				attachment: toAttachment(v?.attachment),
				totalCharge: v?.totalCharge,
				supplier:
					v?.supplier ??
					(this.$currentUser.isSupplier
						? this.$currentUser.supplier
						: null),
				endorsement: v?.endorsement ?? true,
				toEndDate: v?.toEndDate
					? this.$m(v.toEndDate).format('YYYY-MM-DD')
					: null,
				fromStartDate: v?.fromStartDate
					? this.$m(v.fromStartDate).format('YYYY-MM-DD')
					: null,
			}
		},
		onSubmit() {
			if (!this.$refs.formRef.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}

			const type = this.isUpdate ? 'update' : 'create'
			this.$root.confirmation = {
				title: this.$t('type-ad-reservation', [this._.capitalize(type)]),
				message: this.$t('sure-type-ad-reservation', [type]),
				handler: async () => {
					const adReservation = toFormData(
						{
							status: this.adv.status || 'pending',
							advertisementId: this.adv.advertisementId,
							totalCharge: this.totalPrice,
							adSpaceId: this.adv.adSpace?.id,
							supplierId: this.adv.supplier?.id,
							endorsement: +this.adv.endorsement,
							chargePerDay: this.adv.adSpace.chargePerDay,
							attachment: getFile(this.adv.attachment?.file),
							toEndDate: this.$m(this.adv.toEndDate).format(
								'YYYY-MM-DD'
							),
							fromStartDate: this.$m(this.adv.fromStartDate).format(
								'YYYY-MM-DD'
							),
						},
						{ convertCase: 'snake_case' }
					)

					const action = this.isUpdate
						? this.updateAdvertisement
						: this.createAdvertisement

					this.isLoading = true
					const [err, res] = await action(adReservation)
					this.isLoading = false

					if (err) {
						this.$utils.scrollIntoError(this)
						this.$toast.error(
							err.message || this.$t('error-type-ad-reservation', [type])
						)
						return
					}

					this.$toast.success(
						this.$t('ad-reservation-changed-successfully', [
							this.isUpdate ? 'updated' : 'created',
						])
					)

					this.$emit('adReservation', res)
					this.onClose()
				},
			}
		},
	},
}
</script>

<style>
.user-input.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.user-input input::placeholder {
	color: black !important;
	opacity: 0.25;
	font-size: 16px !important;
}
</style>
