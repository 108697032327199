<template>
	<div>
		<base-data-table
			:title="$t('ad-reservations')"
			:headers="headers"
			:actions="actions"
			:provider="$advertisements"
			:fetch-function="getAdvertisements"
			translate
			@view="onInitView"
			@update="onInitUpdate"
			@delete="onDeleteAdvertisement"
			@click:add="onInitAdAdvertisement"
		>
			<template v-slot:[`item.serial`]="{ index }">
				{{
					$advertisements.perPage * ($advertisements.currentPage - 1) +
					index +
					1
				}}
			</template>

			<template v-slot:[`item.totalDays`]="{ item }">
				{{ $m(item.toEndDate).diff(item.fromStartDate, 'days') }}
				{{ $t('days') }}
			</template>
			<template v-slot:[`item.activatedAt`]="{ item }">
				<div>
					{{ $m(item.activatedAt).format('Do MMM YYYY') }}
				</div>
			</template>
			<template v-slot:[`item.fromStartDate`]="{ item }">
				<div>
					{{ $m(item.activatedAt).format('YYYY-MM-DD') }}
				</div>
			</template>
			<template v-slot:[`item.status`]="{ item }">
				<div
					:class="item.status == 'active' ? 'tw-text-AA' : 'tw-text-72'"
					class="tw-capitalize tw-text-sm tw-font-semibold"
				>
					{{ item.status }}
				</div>
			</template>
			<template v-slot:[`item.payment`]="{ item }">
				<div
					class="tw-uppercase tw-text-xs tw-font-bold"
					v-if="
						$get(item, 'order.payments.0.paymentStatus') === 'authorised'
					"
				>
					{{ $t('paid') }}
				</div>
				<div class="tw-uppercase tw-text-xs tw-font-bold" v-else>
					<v-btn
						class="tw-rounded-lg"
						width=""
						height="44"
						outlined
						@click="onClickPayNow(item)"
						color="#F2CA51"
					>
						{{ $t('payNow') }}
					</v-btn>
				</div>
			</template>
			<template v-slot:[`item.toEndDate`]="{ item }">
				<div>
					{{ $m(item.toEndDate).format('YYYY-MM-DD') }}
				</div>
			</template>
		</base-data-table>

		<v-dialog
			v-model="currentAd.isCreateUpdate"
			persistent
			scrollable
			max-width="1198"
			content-class="tw-rounded-2xl tw-shadow-lg"
		>
			<AdForm
				:ad="currentAd.data"
				:error="error"
				:adSpaces="adSpaces"
				@onClose="onResetCurrentAd"
				@onUpdate="onResetCurrentAd"
				@createAd="handleCreateAdvertisement"
			/>
		</v-dialog>

		<base-view-table
			v-model="currentAd.isView"
			:headers="viewHeaders"
			:item="currentAd.data"
			tag="v-dialog"
			title="View Ad Information"
		>
			<!-- CREATOR -->
			<template #creator="{ item }">
				<div>{{ $get(item, 'creator.name') }}</div>
				<div class="tw-opacity-80">
					{{ $get(item, 'creator.email') }}
				</div>
			</template>

			<!-- DURATION -->
			<template #totalDays="{ item }">
				{{ $m(item.toEndDate).diff(item.fromStartDate, 'days') }}
				{{ $t('days') }}
			</template>

			<!-- PAYMENT -->
			<template #payment="{ item }">
				<div class="tw-uppercase tw-text-xs tw-font-bold">
					{{
						$get(item, 'order.payments.0.paymentStatus') === 'authorised'
							? $t('paid')
							: 'None'
					}}
				</div>
			</template>

			<!-- STATUS -->
			<template #status="{ item }">
				<div
					:class="item.status == 'active' ? 'tw-text-AA' : 'tw-text-72'"
					class="tw-capitalize tw-text-sm tw-font-semibold"
				>
					{{ item.status }}
				</div>
			</template>

			<!-- STATUS -->
			<template #attachment="{ item, index }">
				<v-img
					v-if="item.attachment"
					:src="$image($get(item, 'attachment.attachedLink'))"
					:width="320"
					cover
					ref="imgRef"
					class="tw-my-4"
					@error="$onImgError({ index, ref: $refs.imgRef })"
				/>
				<template v-else>None</template>
			</template>
		</base-view-table>

		<DialogDeleteConfirmation
			v-model="confirmDialog"
			hide-id
			:id="onDeleteId"
			:title="$t('adsText')"
			:delete-function="deleteAdvertisement"
		/>

		<v-dialog
			content-class="tw-rounded-3xl tw-bg-white"
			persistent
			max-width="676"
			v-model="confirmStatusDialog"
		>
			<v-card elevation="0" class="tw-p-0 tw-mx-0">
				<div class="tw-text-center tw-py-5 tw-px-6 lg:tw-py-10 lg:tw-px-9">
					<h1 class="tw-text-2xl tw-font-semibold">
						{{
							onChangeStatusData.status === 'active'
								? 'Deactive User'
								: 'Active User'
						}}
					</h1>
					<div
						class="tw-text-black tw-text-opacity-25 tw-mt-6 tw-mb-9 xl:tw-mt-8 xl:tw-mb-14 tw-text-center"
					>
						Are you sure about changing this user status because once you
						continue this action can't be undone.
					</div>
					<v-card-actions class="tw-flex tw-space-s-4">
						<v-btn
							depressed
							color="#DF9999"
							outlined
							@click="confirmStatusDialog = false"
							class="tw-h-14 tw-flex-1 tw-rounded-lg"
							><span class="tw-capitalize tw-text-lg tw-font-medium">{{
								$t('actions.cancel')
							}}</span></v-btn
						>
						<!-- @click="handleChangeUserStatus" -->
						<v-btn
							depressed
							color="#DF9999"
							class="tw-h-14 tw-flex-1 tw-rounded-lg"
							dark
							:loading="loading"
						>
							<span class="tw-capitalize tw-text-lg tw-font-medium">{{
								$t('actions.confirm')
							}}</span></v-btn
						>
					</v-card-actions>
				</div>
			</v-card>
		</v-dialog>
		<!-- @input="attachmentError = false" -->
		<!-- :class="{ 'tw-border-red-500': attachmentError }" -->
	</div>
</template>

<script>
import api from '../../api'
import { toFormData } from 'vuelpers'
import { mapGetters, mapActions } from 'vuex'
import { PAYTAB_PAYMENT_TYPE } from '../../consts'

import AdForm from '@/components/forms/AdForm.vue'
import DialogDeleteConfirmation from '../../components/dialogs/DialogDeleteConfirmation.vue'

const CurrentAd = (v = {}) => ({
	data: null,
	isView: false,
	isDelete: false,
	isStatus: false,
	isCreateUpdate: false,
	...v,
})

export default {
	name: 'adspace',
	components: {
		AdForm,
		DialogDeleteConfirmation,
	},
	data() {
		return {
			adForm: {
				dialog: false,
				data: null,
			},
			currentAd: CurrentAd(),

			viewHeaders: [
				{
					text: 'Supplier',
					value: 'supplier.representativeName',
				},
				{
					text: 'Created By',
					value: 'creator',
				},
				{
					text: 'headers.adRating',
					value: 'adSpace.category.name',
				},
				{
					text: 'headers.costPerDay',
					value: (v) => `SAR ${v.chargePerDay}`,
				},
				{
					text: 'headers.duration',
					value: 'totalDays',
				},
				{
					text: 'headers.cost',
					value: (v) => `SAR ${v.totalCharge}`,
				},
				{ text: 'headers.startDate', value: 'fromStartDate' },
				{ text: 'headers.endDate', value: 'toEndDate' },
				{ text: 'headers.payment', value: 'payment' },
				{ text: 'headers.status', value: 'status' },
				{ text: 'headers.attachment', value: 'attachment' },
			],
			error: {},
			userData: {},
			onView: false,
			onUpdate: false,
			loading: false,

			confirmDialog: false,
			onDeleteId: null,
			onDeactiveId: null,
			onChangeStatusData: {},
			confirmStatusDialog: false,
		}
	},
	computed: {
		...mapGetters('users', ['$users']),
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('adSpaces', ['$adSpaces']),
		...mapGetters('permission', ['$permissions']),
		...mapGetters('advertisements', ['$advertisements']),
		adSpaces() {
			return (this.$adSpaces?.data ?? []).map((adSpace) => {
				return {
					value: adSpace.id,
					label: adSpace.category.name,
				}
			})
		},
		headers() {
			return [
				{ text: 'headers.ns', value: 'serial' },
				this.$currentUser.isAdmin && {
					text: 'orderNumber',
					value: 'order.orderNumber',
				},
				{
					text: 'headers.adRating',
					value: 'adSpace.category.name',
				},
				{
					text: 'headers.costPerDay',
					value: 'chargePerDay',
					computed: (v) => `SAR ${v.chargePerDay}`,
				},
				{
					text: 'headers.duration',
					value: 'totalDays',
				},
				{
					text: 'headers.cost',
					value: 'totalCharge',
					computed: (v) => `SAR ${v.totalCharge}`,
				},
				{ text: 'headers.startDate', value: 'fromStartDate' },
				{ text: 'headers.endDate', value: 'toEndDate' },
				!this.$currentUser.isAdmin && {
					text: 'headers.payment',
					value: 'payment',
				},
				{ text: 'headers.status', value: 'status' },
				{ text: 'headers.actions', value: 'actions' },
			].filter(Boolean)
		},
		actions() {
			return [
				{
					text: 'View details',
					event: 'view',
					icon: 'mdi-eye',
				},
				{
					text: 'Edit Information',
					event: 'update',
					icon: 'mdi-pencil',
				},
				{
					divider: true,
				},
				{
					text: 'Delete',
					event: 'delete',
					icon: 'mdi-delete-outline',
					color: 'error',
				},
				this.$currentUser.isAdmin && {
					text: 'Ad Free',
					event: 'adFree',
					icon: 'mdi-cash',
					color: 'error',
				},
			].filter(Boolean)
		},
	},
	methods: {
		...mapActions('adSpaces', ['getAdSpaces']),
		...mapActions('settings', ['setSettingsState']),
		...mapActions('permission', ['getPermissions']),
		...mapActions('users', [
			'fetchUsers',
			'deleteUser',
			'createUser',
			'updateUser',
			'changeStatusOfUser',
		]),
		...mapActions('advertisements', [
			'createAdvertisement',
			'deleteAdvertisement',
			'updateAdvertisement',
			'changeStatus',
			'getAdvertisements',
		]),
		onResetCurrentAd() {
			this.currentAd = CurrentAd({
				data: this.currentAd.data,
			})
		},
		onInitView(ad) {
			this.currentAd = CurrentAd({
				data: ad,
				isView: true,
			})
		},
		onInitUpdate(ad) {
			this.currentAd = CurrentAd({
				data: ad,
				isCreateUpdate: true,
			})
		},
		onResetForm() {
			this.adForm = {
				dialog: false,
				data: null,
			}
			this.onUpdate = false
			this.onView = false
			this.error = {}
		},
		onInitUser() {
			this.adForm.dialog = true
		},
		async handleCreateAdvertisement(data) {
			const [err] = await this.createAdvertisement(
				toFormData(data, { convertCase: 'snake_case' })
			)

			!err
				? this.$toast.success('Advertisement  deleted successfully')
				: this.$toast.error(
						'An unexpected error occured then deleting Advertisement'
				  )

			this.onResetForm()
		},

		async onDeleteAdvertisement(id) {
			this.onDeleteId = id
			this.confirmDialog = true
		},
		onInitAdAdvertisement() {
			this.currentAd = CurrentAd({
				isCreateUpdate: true,
			})
		},
		async onClickPayNow(order) {
			let [err, res] = await api.post('payment/create', {
				currency: 'SAR',
				orderId: order.orderId,
				type: PAYTAB_PAYMENT_TYPE.ADVERTISEMENT,
			})
			this.setSettingsState({
				currentPayment: {
					tranRef: res.tranRef,
					type: PAYTAB_PAYMENT_TYPE.ADVERTISEMENT,
				},
			})
			!err && location.replace(res.url)
		},
	},
	created() {
		this.getPermissions()
		this.getAdSpaces()
	},
}
</script>
